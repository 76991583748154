import React, { useEffect, useRef } from "react";
import { ActionButton, SecondaryButton } from "../../components/Button/button";
import "./search-input.scss";

type SearchInputProps = {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onSearch: () => void;
  submitting: boolean;
  newSearchEnabled: boolean;
  onClearSearch: () => void;
};

const SearchInputLexebraGPT: React.FC<SearchInputProps> = ({
  inputValue,
  setInputValue,
  onSearch,
  newSearchEnabled,
  submitting,
  onClearSearch,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      autoResizeTextarea();
    }
  }, []);

  const autoResizeTextarea = () => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  return (
    <div className="textarea-button-container">
      <textarea
        className="form-control"
        placeholder="Търсене по правен въпрос"
        ref={ref}
        value={inputValue}
        rows={2}
        onChange={(e) => {
          setInputValue(e.target.value);
          autoResizeTextarea();
        }}
        style={{ resize: "none", overflow: "hidden" }}
      />

      <div className="d-flex gap-1 ps-3">
        <ActionButton
          messageId=""
          disabled={inputValue.trim() === "" || !newSearchEnabled}
          onClick={onSearch}
          submitting={submitting}
        >
          Питай LexebraGPT
        </ActionButton>
        {false && (
          <SecondaryButton messageId="" onClick={onClearSearch} submitting={false}>
            Ново търсене
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default SearchInputLexebraGPT;
