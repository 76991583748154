import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, {
  del,
  get,
  getStream,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import { Summary } from "../models/Summary.model";
import SummaryQa from "../models/SummaryQa.model";

const SUMMARIES_BASE_URL = "/summaries";

export const summaryHref = (id: string): string => `${SUMMARIES_BASE_URL}/${id}`;

export const useSummaries = () => useSearchRequest<Summary>("/summaries");

export const useQuestions = () => useSearchRequest<SummaryQa>("/questions");

export const fetchAnswer = (chunkHandler: (chink: string | null) => void) => {
  getStream(`/questions/answer`, chunkHandler);
};

export const prefetchSummaries = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(SUMMARIES_BASE_URL, searchParams, cache);

export const prefetchQuestions = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest("/questions", searchParams, cache);

export const useSummary = (id?: string) => {
  const { data } = useRequest<EntityModel<Summary>>(id ? `${SUMMARIES_BASE_URL}/${id}` : null);
  return data;
};

export const fetchSummary = (id: string) => {
  return get<EntityModel<Summary>>(`${SUMMARIES_BASE_URL}/${id}`);
};

export const prefetchSummary = (id: string, cache: Cache<any>) => {
  return prefetch(`${SUMMARIES_BASE_URL}/${id}`, cache);
};

export const fetchSummaryPdf = (id: string) => {
  return get<{ data: string }>(`${SUMMARIES_BASE_URL}/${id}/pdf`);
};

export const fetchSummaryRtf = (id: string) => {
  return get<{ data: string }>(`${SUMMARIES_BASE_URL}/${id}/rtf`);
};

export const saveSummary = async (data: Summary, summaryId?: string) => {
  const result = (
    summaryId
      ? await put(`${SUMMARIES_BASE_URL}/${summaryId}`, data)
      : await post(SUMMARIES_BASE_URL, data)
  ) as EntityModel<Summary>;
  mutate(`${SUMMARIES_BASE_URL}/${summaryId}`, () => result, false);
  return result;
};

export const addTopicToSummary = async (summaryId: string, topicId: string | number) => {
  await post(`${SUMMARIES_BASE_URL}/${summaryId}/topics/${topicId}`, {}, true);
};

export const removeTopicFromSummary = async (summaryId: string, topicId: string | number) => {
  await del(`${SUMMARIES_BASE_URL}/${summaryId}/topics/${topicId}`, {}, true);
};
