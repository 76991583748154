import { useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import "./button.css";

export const PrimaryButton = ({ messageId = "", submitting = false, ...props }) => (
  <Button {...props} variant="light" className={`load-more ${props.className || ""}`} size="sm">
    {submitting && (
      <span style={{ marginRight: "5px" }}>
        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        {/* <Loader type="TailSpin" color="#ffffff" width="14px" height="14px" /> */}
      </span>
    )}
    {messageId ? <FormattedMessage id={messageId} /> : props.children}
  </Button>
);

export const ActionButton = ({ messageId = "", submitting, ...props }) => {
  return (
    <Button {...props} variant="light" className="important" size="sm">
      {submitting && (
        <span style={{ marginRight: "5px" }}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          {/* <Loader type="TailSpin" color="#ffffff" width="14px" height="14px" /> */}
        </span>
      )}
      {messageId ? <FormattedMessage id={messageId} /> : props.children}
    </Button>
  );
};

export const SubmittingActionButton = ({ messageId = "", ...props }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Button
      {...props}
      disabled={isSubmitting}
      onClick={async (e) => {
        try {
          setIsSubmitting(true);
          await props.onClick(e);
        } finally {
          setIsSubmitting(false);
        }
      }}
      variant="light"
      className="important"
      size="sm"
    >
      {isSubmitting && (
        <span style={{ marginRight: "5px" }}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          {/* <Loader type="TailSpin" color="#ffffff" width="14px" height="14px" /> */}
        </span>
      )}
      {messageId ? <FormattedMessage id={messageId} /> : props.children}
    </Button>
  );
};

export const SecondaryButton = ({ messageId, ...props }) => (
  <Button {...props} variant="light" className="secondary" size="sm">
    {messageId ? <FormattedMessage id={messageId} /> : props.children}
  </Button>
);

export const InlineLinkButton = ({ messageId = "", ...props }) => (
  <Button {...props} variant="link" className={`inline-link ${props.className}`} size="sm">
    {messageId ? <FormattedMessage id={messageId} /> : props.children}
  </Button>
);
