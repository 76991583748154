import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { FaHistory } from "react-icons/fa";
import { useIntl } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Collapse from "../../components/Collapse";
import { ManageScroll } from "../../components/ManageScroll";
import SearchFieldController from "../../components/SearchFieldController/search-field-controller";
import { withActiveSubscrption } from "../../contexts/auth.context";
import AppPageLayout from "../DocumentPage/app-page-layout";
import { useQuery } from "../SearchPage/search-utils";
import SearchInputLexebraGPT from "./search-input";
import "./search-landing.scss";
import SearchResultsLexebraGPT from "./search-results";

const AI_PREFIX = ">!>";

const SearchLexebraGPTLanding = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const query = useQuery();

  const [inputValue, setInputValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [hasAnswer, setHasAnswer] = useState(false);

  const handleClearSearch = () => {
    setInputValue("");
    setHasAnswer(false);
    navigate("/lexebragpt-search");
  };

  const answerGenerated = useCallback(() => {
    setSubmitting(false);
    setHasAnswer(true);
  }, []);

  useEffect(() => {
    if (query?.query) setInputValue(("" + query.query).replace(AI_PREFIX, ""));
  }, [query?.query]);

  return (
    <AppPageLayout
      title={intl.formatMessage({ id: "landing-page.title" })}
      className="search-landing-page"
      navigation={<SearchLexebraGPTNavigation />}
    >
      <ManageScroll />
      <SearchFieldController
        name="query"
        render={({ field: { onChange } }) => {
          const performSearch = async (searchText: string) => {
            if (!searchText) {
              return;
            }
            setHasAnswer(false);
            setSubmitting(true);
            const { navigate } = await onChange(
              {
                query: [AI_PREFIX + searchText],
                size: 5,
                page: "0",
              },
              false
            );
            navigate(pathname);
          };

          return (
            <>
              <SearchInputLexebraGPT
                inputValue={inputValue}
                setInputValue={setInputValue}
                onSearch={() => performSearch(inputValue)}
                submitting={submitting}
                newSearchEnabled={
                  hasAnswer && query && ("" + query.query).replace(AI_PREFIX, "") !== inputValue
                }
                onClearSearch={handleClearSearch}
              />
              {/* {!hasSearched && (
                <SampleSearchCards setInputValue={setInputValue} onSearch={performSearch} />
              )} */}
            </>
          );
        }}
      />
      <div className="search-results-container">
        <SearchResultsLexebraGPT
          setAnswerGenerated={answerGenerated}
          hasAnswer={hasAnswer}
          question={query?.query?.replace(AI_PREFIX, "")}
        />
      </div>
    </AppPageLayout>
  );
};

const SearchLexebraGPTNavigation = () => {
  const [searchInput, setSearchInput] = useState("");
  const mockSearchHistoryToday = [
    "Връщане на кредит, уговорен в чуждестранна валута",
    "Присъждане на законна лихва за забава при недействителен потребителски кредит",
    "Кога настъпва преклузията за възражението на ответника по иск за трансформация на лично имущество, че дарителят на ищеца (позоваващият се на трансформацията съпруг) не е разполагал с имуществото, което се твърди да е дарил?",
    "Прекъсва ли погасителната давност отхвърления отрицателен установителен иск за вземане, срещу който ответникът е направил само възражение по основателността?",
  ];

  const mockSearchHistoryYesterday = [
    "Права на наемодателя при неизплатен наем",
    "Трудово законодателство при работа от вкъщи",
  ];

  const mockSearchHistoryLast10Days = ["Семейно право", "Закон за защита на потребителите"];

  const filteredSearchHistoryToday = mockSearchHistoryToday.filter((item) =>
    item.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredSearchHistoryYesterday = mockSearchHistoryYesterday.filter((item) =>
    item.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredSearchHistoryLast10Days = mockSearchHistoryLast10Days.filter((item) =>
    item.toLowerCase().includes(searchInput.toLowerCase())
  );
  return (
    <Container
      fluid
      className="filters-sidebar"
      style={{
        boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
      }}
    >
      <Collapse icon={<FaHistory />} label="История на търсенията">
        <Form.Control
          type="text"
          placeholder="Търсене..."
          className="mt-2 mb-2"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />

        {filteredSearchHistoryToday.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Днес
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryToday.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <Link to={`/lexebragpt-search?q=${encodeURIComponent(searchItem)}`}>
                    {searchItem}
                  </Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}

        {filteredSearchHistoryYesterday.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Вчера
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryYesterday.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <Link to={`/lexebragpt-search?q=${encodeURIComponent(searchItem)}`}>
                    {searchItem}
                  </Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}

        {filteredSearchHistoryLast10Days.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Последните 10 дни
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryLast10Days.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <Link to={`/lexebragpt-search?q=${encodeURIComponent(searchItem)}`}>
                    {searchItem}
                  </Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}
      </Collapse>
    </Container>
  );
};

export default withActiveSubscrption(SearchLexebraGPTLanding);
